import * as React from 'react'
import { Parallax } from 'react-parallax'
import Fade from 'react-reveal/Fade'
import { Helmet } from 'react-helmet'
import { SmoothProvider } from 'react-smooth-scrolling'
import { Link } from 'gatsby'
import { navigate } from "gatsby"

import CookieConsent from 'react-cookie-consent'

// Styles
import '../styles/styles.scss'

// Images
import header from '../images/header.jpg'
import spacer from '../images/spacer.jpg'
import stefanSig from '../images/stefan_sig.png'
import stefanSeated from '../images/stefan_seated.jpg'
import introBottleBg from '../images/intro_bottle_bg.jpg'
import spacerBottleBg from '../images/spacer_bottle_bg.jpg'
import spacerBottleBg2 from '../images/spacer_bottle_bg2.jpg'
import spacerFooterBg from '../images/spacer_footer_bg.jpg'
import shs from '../images/shs.jpg'

import wine_mu from '../images/wine/Klasse_Steira_MU.jpg'
import wine_sb from '../images/wine/Klasse_Steira_SB.jpg'
import wine_wb from '../images/wine/Klasse_Steira_WB.jpg'
import wine_wr from '../images/wine/Klasse_Steira_WR.jpg'


// markup
const IndexPage = () => {
  return (
    <>
    <CookieConsent
      enableDeclineButton
      flipButtons
      location="bottom"
      buttonText="Akzeptieren"
      declineButtonText="Ablehnen"
      cookieName="gatsby-gdpr-google-analytics"
      onAccept={()=>navigate('/')}
      buttonStyle={{ backgroundColor: '#FFF', color: "#000", fontSize: "16px" }}
      declineButtonStyle={{ backgroundColor: '#FFF', color: "#000", fontSize: "16px", opacity: 0.5 }}
      contentStyle={{fontSize: '18px'}}
      >
      Bitte erteilen Sie uns die Zustimmung, Ihre Daten zur internen Analyse zu verwenden. Wir geben Ihre Daten nicht weiter. Lesen Sie auch unsere Datenschutzerklärung.
    </CookieConsent>
    <div className="badge rotate"></div>
      <Helmet title="Klasse Steira | 4 Klasse Weine | Weingut Krispel" defer={false} />
      <div className="container">
        <Parallax bgImage={header} strength={-150}> 
          <header>
            <Fade bottom ssrReveal>
              <div className="logo"></div>
              <div className="title"></div>
            </Fade>
          </header>
        </Parallax>
        <div className="intro">
          <div className="stefan">
          <Parallax bgImage={stefanSeated} className="para" strength={250}> 
            <svg class="svg">
              <clipPath id="introWave" clipPathUnits="objectBoundingBox"><path d="M1,0.415 S0.867,0.047,0.68,0.08 C0.504,0.147,0.445,0.312,0.278,0.3 S0,0,0,0 V0.77 S0.073,1,0.252,1 c0.243,0.001,0.282,-0.577,0.465,-0.654 S0.988,0.461,0.988,0.461"></path></clipPath>
            </svg>
          </Parallax>
          </div>
          <div className="bottle">
            <Parallax bgImage={introBottleBg} className="para" strength={-100}> 
              <svg class="svg">
                <clipPath id="introBottle" clipPathUnits="objectBoundingBox"><path style={{ strokeWidth: 2}} d="M0.346,0.556,0.846,0.996 s0.016,0.016,0.041,-0.016 c0,0,0.039,-0.051,0.057,-0.083 l0,0 c0.019,-0.03,0.049,-0.101,0.049,-0.101 c0.017,-0.04,0,-0.055,0,-0.055 L0.507,0.275 S0.467,0.224,0.392,0.225 s-0.145,-0.055,-0.145,-0.055 L0.108,0.041 l-0.006,-0.008 L0.074,0.01 S0.057,-0.027,0.018,0.039 C-0.02,0.107,0.013,0.116,0.013,0.116 L0.039,0.142 l0.008,0.005 L0.187,0.276 s0.063,0.066,0.086,0.157 c0.016,0.065,0.046,0.1,0.062,0.114 a0.085,0.108,0,0,0,0.01,0.008"></path></clipPath>
              </svg>
            </Parallax>
          </div>
          <div className="row abs">
            <div className="col two-third">
              <Fade bottom>
                <div className="signature">
                  <img src={stefanSig} />
                </div>
                <h3><b>Klasse Steira</b> erfreuen sich am Leben und an den Dingen, die uns das Leben lebenswert machen.</h3>
                <p>Exklusiv für Weingenießer holte ich einige junge, steirische Winzer an Bord, um einen frischen und dynamischen Wein zu machen.</p>
                <p>Das Resultat sind klasse Steira – Weine die spritzig, lebendig und mit typisch steirischen Sorten für Begeisterung sorgen!</p>
                <p>– Stefan Krispel</p>
                <p><small><i><a href="https://www.krispel.at" target="_blank">Mehr Information zu Stefan Krispels Weinen gefällig</a></i></small></p>
              </Fade>
            </div>
          </div>
        </div>
        <div className="bottleSpacer">
          <div className="first">
            <Parallax bgImage={spacerBottleBg} className="para" strength={100}> 
              <svg class="svg">
                <clipPath id="spacerBottle" clipPathUnits="objectBoundingBox"><path d="M0.539,0.062,0.018,0.418 s-0.018,0.009,-0.015,0.095 c0,0,0.004,0.135,0.011,0.205 h0 C0.019,0.789,0.038,0.932,0.038,0.932 c0.012,0.08,0.029,0.067,0.029,0.067 l0.526,-0.298 s0.049,-0.009,0.092,-0.145 s0.112,-0.193,0.112,-0.193 l0.148,-0.093,0.008,-0.001,0.028,-0.022 s0.029,0.014,0.017,-0.138 c-0.014,-0.151,-0.038,-0.103,-0.038,-0.103 L0.933,0.021 l-0.007,0.009 L0.777,0.122 s-0.071,0.032,-0.132,-0.04 c-0.043,-0.051,-0.079,-0.04,-0.096,-0.029 A0.074,0.202,0,0,0,0.539,0.062"></path></clipPath>
              </svg>
            </Parallax>
          </div>
          <div className="wave">
            <div className="left">
              <Parallax bgImage={spacer} className="para" strength={100}> 
                <svg class="svg">
                  <clipPath id="spacerWave" clipPathUnits="objectBoundingBox"><path d="M1,0.587 c-0.068,-0.14,-0.179,-0.297,-0.356,-0.184 C0.286,0.631,0.003,0.03,0.001,0.021 s0,0.72,0,0.72 s0.182,0.448,0.455,0.21 S0.713,0.518,0.966,0.741 C0.968,0.74,1,0.587,1,0.587"></path></clipPath>
                </svg>
              </Parallax>
            </div>
            <div className="right">
              <Parallax bgImage={spacerBottleBg2} className="para" strength={100}> 
                <svg class="svg">
                  <clipPath id="spacerBottle2" clipPathUnits="objectBoundingBox"><path d="M1,0.572,0.586,0.247 S0.537,0.195,0.453,0.208 S0.285,0.166,0.285,0.166 l-0.169,-0.126,-0.008,-0.009 L0.076,0.01 S0.053,-0.03,0.015,0.052 c-0.036,0.083,0.002,0.089,0.002,0.089 L0.049,0.167,0.058,0.171 l0.169,0.126 S0.304,0.364,0.338,0.464 c0.024,0.072,0.061,0.107,0.081,0.122 a0.097,0.125,0,0,0,0.012,0.008 L1,1"></path></clipPath>
                </svg>
              </Parallax>
            </div>
          </div>
        </div>
        <div className="wines">
          <div className="row">
            <div className="col two-third">
              <Fade bottom>
                <h2>4 Klasse Weine</h2>
                <p className="big">Die Steiermark ist bekannt für ihr mildes Klima und ihre Böden, wo unsere fruchtbetonten Weine mit ihrer frische und eleganz entstehen.</p>
              </Fade>
            </div>
          </div>
          <div className="wine-grid">
            <div className="wine">
              <Fade bottom>
                <img src={wine_mu} />
                <h3>Gelber Muskateller</h3>
                <p>Steiermark<br />Alkohol: 12,5 vol%<br />Trocken, enthält Sulfite</p>
                <p>Der Gelbe Muskateller bietet einen Fruchtgenuss in seiner schönsten Form. Der Geschmack nach Holunder, Zitrus und Wildrosen macht ihn zum perfekten Sommerwein: Per Hand gelesen, sorgsam vergoren, ausgebaut im Stahltank.</p>
              </Fade>
            </div>
            <div className="wine">
              <Fade bottom>
                <img src={wine_sb} />
                <h3>Sauvignon Blanc</h3>
                <p>Steiermark<br />Alkohol: 12,5 vol%<br />Trocken, enthält Sulfite</p>
                <p>Präzise, starke Sortencharakteristik mit Stachelbeere und schwarzer Johannisbeere, der typischen knackigen und frischen Säure: Per Hand gelesen, sorgsam vergoren und ausgebaut im Stahltank bietet der Sauvignon Blanc sein typisches Trinkvergnügen.</p>
              </Fade>
            </div>
            <div className="wine">
              <Fade bottom>
                <img src={wine_wb} />
                <h3>Weissburgunder</h3>
                <p>Steiermark<br />Alkohol: 12,5 vol%<br />Trocken, enthält Sulfite</p>
                <p>Fruchtiges Bouquet nach reifer Birne, am Gaumen leicht nussig mit gut eingebundener Säure. Ein perfekter Speisenbegleiter! Per Hand gelesen, sorgsam vergoren und ausgebaut im Stahltank, entfaltet der Weißburgunder sein edles Aroma.</p>
              </Fade>
            </div>
            <div className="wine">
              <Fade bottom>
                <img src={wine_wr} />
                <h3>Welschriesling</h3>
                <p>Steiermark<br />Alkohol: 12,5 vol%<br />Trocken, enthält Sulfite</p>
                <p>Der steirische Klassiker für Aperitif und Brettljause! Gelber Apfel und Zitrus in der Nase, lebendige und animierende Säure am Gaumen. Per Hand gelesen, sorgsam vergoren, ausgebaut im Stahltank, so entsteht die pure Trinkfreude.</p>
              </Fade>
            </div>
            <div className="wine">
              <a href="https://www.interspar.at/shop/weinwelt/search/?sp_cs=UTF-8&q=Klasse+Steira" target="_blank" className="btn">Zur Interspar-Weinwelt</a>
            </div>
          </div>
        </div>
        <div className="footer-spacer">
          <div className="left">
            <Parallax bgImage={spacerFooterBg} className="para" strength={-100}> 
              <svg class="svg">
                <clipPath id="footerSpaceBottle" clipPathUnits="objectBoundingBox"><path d="M0.978,0.756 l-0.032,-0.023 L0.937,0.732,0.77,0.633 s-0.078,-0.059,-0.126,-0.195 c-0.034,-0.098,-0.072,-0.131,-0.091,-0.142 a0.086,0.204,0,0,0,-0.012,-0.005 h0 L0,0 V0.615 L0.474,0.918 s0.051,0.052,0.121,-0.015 s0.15,-0.033,0.15,-0.033 l0.167,0.099,0.008,0.009,0.032,0.015 s0.027,0.048,0.044,-0.1 C1,0.743,0.978,0.756,0.978,0.756"></path></clipPath>
              </svg>
            </Parallax>
          </div>
          <div className="right">
            <Parallax bgImage={header} className="para" strength={-200}> 
              <svg class="svg">
                <clipPath id="footerSpaceWave" clipPathUnits="objectBoundingBox"><path d="M0.011,0.013 C0.216,0.065,0.286,-0.066,0.535,0.049 s0.286,0.529,0.419,0.543 c0.027,0.009,0.046,0,0.046,0 V0.983 s-0.03,0.038,-0.116,0 C0.619,0.83,0.679,0.371,0.467,0.235 S0.127,0.196,0,0.093 C0.002,0.097,0.011,0.013,0.011,0.013"></path></clipPath>
              </svg>
            </Parallax>
          </div>
        </div>
        <div className="footer">
          <Fade bottom>
            <div className="shs">
              <img src={shs} alt="Steirer helfen Steirern" />
            </div>
            <div className="shs-hl">
              <h3>Jede gekaufte Flasche unterstützt<br /><a href="https://www.kleinezeitung.at/steiermark/steirerhelfen/index.do" target="_blank">» Steirer helfen Steirern «</a></h3>
            </div>
            <div className="nav">
              <Link 
                to="/impressum/"
                state={{
                  modal: true
                }}
              >
                Impressum
              </Link>
              <Link 
                to="/datenschutzerklaerung/"
                state={{
                  modal: true
                }}
              >
                Datenschutzerklärung
              </Link>
            </div>
          </Fade>
        </div>
      </div>
    </>
  )
}

export default IndexPage
